import React, { createContext, useState } from 'react'

const userContext = createContext()

function UserContextProvider(props) {

    const [user, setUser] = useState({
        username: '',
        password: '',
        isLogged: false,
    })

    function setUserData(user) {
        setUser(user)
    }

    return(
        <userContext.Provider
            value = {{
                user,
                setUserData
            }}
        >
            {props.children}
        </userContext.Provider>
    )
}


export {userContext, UserContextProvider}