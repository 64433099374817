import React, { useContext } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Container,
  Box
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

import { userContext } from '../../contexts/User/UserContext';

export default function Login() {

  const navigate = useNavigate()

  const { user, setUserData } = useContext(userContext)

  const onInputChange = (evt) => {

    setUserData({
      ...user,
      [evt.target.name]: evt.target.value
    })

  }

  const loginHandle = (evt) => {
    if (user.username === 'admin' && user.password === 'admin') {
      setUserData({
        ...user,
        isLogged: true
      })

      navigate('/')
    }
  }

  return (
    <Box color={useColorModeValue('gray.700', 'gray.200')} >
      <form onSubmit={loginHandle}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Stack spacing={4} w={'full'} maxW={'md'}>
            <Heading fontSize={'2xl'}>Login</Heading>
            <FormControl id="username">
              <FormLabel>Username</FormLabel>
              <Input
                name="username"
                value={user.username}
                type="text"
                onChange={onInputChange}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                name="password"
                value={user.password}
                type="password"
                onChange={onInputChange}
              />
            </FormControl>
            <Stack spacing={6}>
              <Button
                colorScheme={'purple'}
                variant={'solid'}
                onClick={loginHandle}
                type={'submit'}
              >
                Login
              </Button>
            </Stack>
          </Stack>

        </Container>
      </form>
    </Box>
  )
}
