import React, { useState, useEffect } from 'react'

import {
  Button,
  FormControl,
  Heading,
  Stack,
  useColorModeValue,
  Container,
  Box,
  Select,
  FormHelperText,
  Text,
} from '@chakra-ui/react';

import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'

import {
  getFunctions,
  enableFunction,
  disableFunction,
  startTrivia,
  getRegisteredPlayers,
  getOnlinePlayers,
  sendCmdShowAccessCode,
} from '../../services/backRequest';

import EnableFunction from '../EnableFunction/EnableFunction';

export default function FunctionControl() {

  const [triviaControl, setTriviaControl] = useState({
    functions: [],
    selectedFunction: '',
    status: 'disabled',
    registeredPlayers: 0,
    onlinePlayers: 0,
    enabledFunction: {},
  })

  useEffect(() => {
    getFunctions()
      .then((response) => {
        const enable = response.find(element => element.enable === true)
        setTriviaControl((oldState) => {
          return ({
            ...oldState,
            functions: response,
            selectedFunction: enable === undefined ? response[0].accessCode : enable.accessCode,
            status: enable === undefined ? 'disabled' : 'enable',
            enabledFunction: enable === undefined ? {} : enable
          })
        })
      })
      .catch((error) => console.error(error.message))

  }, [])

  useEffect(() => {
    const interval = setInterval( async () => {
      if (triviaControl.status === 'enable' || triviaControl.status === 'show ac') {
        try {
          const registered = await getRegisteredPlayers(triviaControl.selectedFunction)
          const online = await getOnlinePlayers()

          setTriviaControl({
            ...triviaControl,
            registeredPlayers: registered,
            onlinePlayers: online
          })

        } catch (error) {
          console.log(error.message)
          setTriviaControl({
            ...triviaControl,
            registeredPlayers: 0,
            onlinePlayers: 0
          })
        }
      }
    }, 1000);

    return () => clearInterval(interval);

  }, [triviaControl]);


  const handleSelectChange = (evt) => {
    setTriviaControl({
      ...triviaControl,
      [evt.target.name]: evt.target.value
    })
  }

  const handleClick = (evt) => {

    switch (evt.target.name) {
      case 'btnEnable':
        enableFunction(triviaControl.selectedFunction)
          .then((response) => {
            setTriviaControl({
              ...triviaControl,
              status: 'enable',
              enabledFunction: response.enabledFunction
            })

            Swal.fire({
              title: `${response.message}`,
              text: `Función ${response.enabledFunction.accessCode} enable`,
              icon: 'success',
              confirmButtonText: 'OK'
            })
          })
          .catch((error) => {
            console.error(error)
            Swal.fire({
              text: `${error.message}`,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        break;

      case 'btnShowAccessCode':
        sendCmdShowAccessCode()
          .then(() => {
            setTriviaControl({
              ...triviaControl,
              status: 'show ac'
            })
            Swal.fire({
              title: `ok`,
              text: `CMD to show access code send OK`,
              icon: 'success',
              confirmButtonText: 'OK'
            })
          })
          .catch((error) => {
            console.error(error)
            Swal.fire({
              text: `${error.message}`,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        break

      case 'btnStart':
        startTrivia(triviaControl.selectedFunction)
          .then((response) => {

            setTriviaControl({
              ...triviaControl,
              status: 'running'
            })

            Swal.fire({
              title: `${response.message}`,
              text: `Trivia ${triviaControl.selectedFunction} start OK`,
              icon: 'success',
              confirmButtonText: 'OK'
            })
          })
          .catch((error) => {
            console.error(error)
            Swal.fire({
              text: `${error.message}`,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        break;

      case 'btnEnd':
        disableFunction(triviaControl.selectedFunction)
          .then((response) => {

            setTriviaControl({
              ...triviaControl,
              status: 'disabled'
            })

            Swal.fire({
              title: `${response.message}`,
              text: `Función ${response.enabledFunction.accessCode} disabled`,
              icon: 'success',
              confirmButtonText: 'OK'
            })
          })
          .catch((error) => {
            console.error(error)
            Swal.fire({
              text: `${error.message}`,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        break;

      default:
        break;
    }
  }

  console.log(triviaControl)

  return (

    <Box color={useColorModeValue('gray.700', 'gray.200')} >
      <Container
        maxW={'6xl'}
        py={4}
        spacing={4}
      >
        <Stack spacing={5} w={'full'} maxW={'md'} m={'20px 0 0 0'}>
          <Heading fontSize={'2xl'}>Listado de funciones</Heading>
          <FormControl>
            <Select
              disabled={triviaControl.status === 'disabled' ? false : true}
              onChange={handleSelectChange}
              value={triviaControl.selectedFunction}
              name='selectedFunction'
            >
              {triviaControl.functions.map((item, index) => {
                return (
                  <option key={index} value={item.accessCode}>{`${item.accessCode.toUpperCase()} - ${new Date(item.date).toLocaleString().split(',')[0]} - ${item.startTime}`}</option>
                )
              })}
            </Select>
            <FormHelperText>Seleccione la función que desea habilitar</FormHelperText>
          </FormControl>

          {triviaControl.status === 'enable' && <EnableFunction enabledFunction={triviaControl.enabledFunction}/>}

          <Stack spacing={6}>
            <Button
              name={'btnEnable'}
              colorScheme={'purple'}
              variant={'solid'}
              onClick={handleClick}
              disabled={triviaControl.status === 'disabled' ? false : true}
            >
              Habilitar Función
            </Button>
          </Stack>
        </Stack>

        <Stack spacing={5} w={'full'} maxW={'md'} m={'20px 0 0 0'}>
          <Heading fontSize={'2xl'}>Comandos</Heading>
          <Stack spacing={6}>
            <Button
              name={'btnShowAccessCode'}
              colorScheme={'green'}
              variant={'solid'}
              onClick={handleClick}
              disabled={triviaControl.status === 'enable' ? false : true}
            >
              Show Access Code
            </Button>
            <Button
              name={'btnStart'}
              colorScheme={'teal'}
              variant={'solid'}
              onClick={handleClick}
              disabled={triviaControl.status === 'show ac' ? false : true}
            >
              Start Trivia
            </Button>
          </Stack>
          <Stack spacing={6}>
            <Button
              name={'btnEnd'}
              colorScheme={'orange'}
              variant={'solid'}
              onClick={handleClick}
              disabled={triviaControl.status === 'disabled' ? true : false}
            >
              Deshabilitar Función
            </Button>
          </Stack>
        </Stack>

        <Stack spacing={5} w={'full'} maxW={'md'} m={'20px 0 0 0'}>
          <Heading fontSize={'2xl'}>Metricas</Heading>
          <Stack spacing={6}>
            <Text>
              Registrados: { triviaControl.status === 'disabled' ? 'Funcion no habilitada' :
              triviaControl.registeredPlayers }
            </Text>
          </Stack>
          <Stack spacing={6}>
            <Text>
              Jugadores: { triviaControl.status === 'disabled' ? 'Función no habilitada' :
              triviaControl.onlinePlayers }
            </Text>
          </Stack>
        </Stack>

      </Container>

    </Box>
  )
}
