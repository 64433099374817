import React from 'react'

import {
  Heading,
  Box,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

export default function EnableFunction({ enabledFunction }) {

  return (
    <Box py={'10px'}>
      <Heading p={'0 0 10px 0'} color={'teal'} fontSize={'2xl'}>Función habilitada </Heading>

      <Box justifyContent={'start'} >
        <UnorderedList spacing={'5px'} p={{base: '0 0 0 10px', md: '0 0 0 20px'}}>
          <PropItem name={'Access Code'} value={enabledFunction.accessCode.toUpperCase()} />
          <PropItem name={'Date'} value={new Date(enabledFunction.date).toLocaleString().split(',')[0]}/>
          <PropItem name={'Start Time'} value={`${enabledFunction.startTime}`} />
          {/* <PropItem name={'URL'} value={enabledFunction.urlSecret} /> */}
        </UnorderedList>
      </Box>
    </Box>
  )
}

function PropItem({ name, value }) {
  return (
    <ListItem>
      <Text as={'b'} >
        {`${name}: `}
      </Text>
      {` ${value}`}
    </ListItem>
  )
}
