import React, {useContext} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';

import { userContext } from './contexts/User/UserContext';

import Footer from './components/Footer/Footer'
import Header from './components/Header/Header';
import Register from './components/Register/Register';
import FunctionControl from './components/FunctionsControl/FunctionControl';
import Login from './components/Login/Login'

function App() {

  const { user } = useContext(userContext)

  return (

      <BrowserRouter>

        <ChakraProvider theme={theme}>

          <Header />

          <Routes>
            <Route path='/' element={ user.isLogged ? <FunctionControl /> : <Login /> } />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
          </Routes>

          <Footer />

        </ChakraProvider>

      </BrowserRouter>

  );
}

export default App;
