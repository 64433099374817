import React from 'react'

import {
  Button,
  // Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  // Link,
  Stack,
  useColorModeValue,
  Container,
  Box
} from '@chakra-ui/react';

export default function Register() {

  return (
    <Box color={useColorModeValue('gray.700', 'gray.200')} >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize={'2xl'}>Creación de usuario admin</Heading>
          <FormControl id="username">
            <FormLabel>Username</FormLabel>
            <Input type="username" />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Password</FormLabel>
            <Input type="password" />
          </FormControl>
          <Stack spacing={6}>
            {/* <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={'start'}
              justify={'space-between'}>
              <Checkbox>Recordar</Checkbox>
              <Link color={'blue.500'}>Olvido su contraseña?</Link>
            </Stack> */}
            <Button colorScheme={'purple'} variant={'solid'}>
              Registrar
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}