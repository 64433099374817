
// const testBackURL = 'https://mc10-trivia-sync-api.herokuapp.com'
const testBackURL = 'https://api.app.mch.ar'
// const testBackURL = 'http://localhost:4000'

export const getFunctions = async () => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/functions`, {
            method: 'GET',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return(jsonResponse.functions)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        return error
    }
}


export const getEnableFunctions = async () => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/functions/enable`, {
            method: 'GET',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return(jsonResponse.message)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        return error
    }
}


export const enableFunction = async (accessCode) => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/functions/enable/${accessCode}?language=en`, {
            method: 'PATCH',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return(jsonResponse)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        throw new Error(error.message)
    }
}


export const sendCmdShowAccessCode = async () => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/cmds/showAccessCode`, {
            method: 'POST',
            headers: {
				"Content-Type": "application/json",
			}
        })
        const jsonResponse = await response.json()
        if (jsonResponse.success === true)
            return true
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        throw new Error(error.message)
    }
}


export const disableFunction = async (accessCode) => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/functions/disable/${accessCode}`, {
            method: 'PATCH',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return (jsonResponse)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        throw new Error(error.message)
    }
}

export const startTrivia = async (accessCode) => {
    try {
        const response = await fetch(`${testBackURL}/api/v1/cmds/start/${accessCode}`, {
            method: 'POST',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return (jsonResponse)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        throw new Error(error.message)
    }
}

export const getRegisteredPlayers = async (accessCode) => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/players/registered/${accessCode}`, {
            method: 'GET',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return(jsonResponse.cant)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        throw new Error(error.message)
    }
}

export const getOnlinePlayers = async () => {
    try {

        const response = await fetch(`${testBackURL}/api/v1/players/online`, {
            method: 'GET',
            headers: {
				"Content-Type": "application/json",
			}
        })

        const jsonResponse = await response.json()

        if (jsonResponse.success === true)
            return(jsonResponse.onlinePlayers)
        else
            throw new Error(jsonResponse.message)

    } catch (error) {
        throw new Error(error.message)
    }
}