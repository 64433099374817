import React from 'react'
import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher/ColorModeSwitcher';

export default function Header() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={'row'}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Text
          fontSize={{ base: '2xl', md: '4xl' }}
        >
          MC10 Trivia Admin
        </Text>
        <ColorModeSwitcher />
      </Container>
    </Box>
  )
}
